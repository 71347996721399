import React from 'react';

export default function ChallengePage() {
  return <div className='flex flex-row w-full min-h-full divide-x divide-slate-600'>
    <div className='w-2/3'>
    {/* <div className="border-4">This is the Challenge Page.</div> */}
      <div style={{color: "white"}}>This is the Challenge Page.</div>
    </div>
    <div className='w-1/3'></div>
  </div>
    // <HumanMachineInterface />
    // <div className="ui grid !m-0">
    //   <div className="three wide column !border-2">
    //     <div className="ui secondary vertical pointing menu !border !w-full">
    //       <a class="item !p-2 !text-lg !pl-12">
    //         Track 1
    //       </a>
    //       <a class="item !p-2 !text-lg !pl-12">
    //         Track 2
    //       </a><a class="item active !p-2 !text-lg !pl-12">
    //         Track 3
    //       </a>
    //       </div>
    //   </div>
    //   <div className="thirteen wide stretched column !border-2">
    //     {/* <div className="ui segment"> */}
    //     <HumanMachineInterface />
    //     {/* </div> */}
    //   </div>
    // </div>
}
